body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.ex-header{
  /* width: 100%; */
  /* height: 80px; */
  box-shadow: 0px 2px 20px 1px  rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  background-color: white;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 600;
}

.desc li{
  margin-top: 1em;
}

.stud-btn{
  padding: 0.5em 1em;
}

.desc {
  overflow-y: auto;
  margin-right: 10px;
  padding-bottom: 5em;
}

@keyframes anim{
  0% {
    transform: translate(-50%) translateY(-50%);
  }
  100% {
    transform: translate(-50%) translateY(-50%) rotateZ(360deg);
  }
}
.loading {
  animation: anim 0.3s linear infinite;
  position: fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%) translateY(-50%);
}

@media screen and (max-width: 600px) {
  .btm{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
  }
  .stud-btn{
    padding: 0.7em 1em;
    width: 100%;
  }
}